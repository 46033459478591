import React from 'react';
import { useNavigate } from "react-router-dom";

import kristaBoha from './quotes/1.mp3';
import aniJaSiTuKokotNevalam from './quotes/2.mp3';
import coRiesisAkeVeciRiesis from './quotes/3.mp3';
import doPiciTamKurva from './quotes/4.mp3';
import dobreOkayTak from './quotes/5.mp3';
import osemKrat from './quotes/6.mp3';
import kurva from './quotes/7.mp3';
import nechateMaKokotinuPovedat from './quotes/8.mp3';
import nekomunikuju from './quotes/9.mp3';
import noNo from './quotes/10.mp3';
import parada from './quotes/11.mp3';
import piceAjSTakouSpolupracou from './quotes/12.mp3';
import toJeKatastrofa from './quotes/13.mp3';
import uzSomNaozajNasratyDoPici from './quotes/14.mp3';
import dakujemToJeVsetko from './quotes/15.mp3';
import precoSteMaTakZarezali from './quotes/16.mp3';
import doPice from './quotes/17.mp3';
import nekricTuNaMnaJaSiTuKokotNevalam from './quotes/18.mp3';
import precoMusisFurtKricatNaNas from './quotes/19.mp3';
import vsakSaTuSnazimeKurvaRobitOkoloVas from './quotes/20.mp3';

function App() {
  const quotes = {
    1: {
      name: "krista boha!",
      path: kristaBoha,
      new: false,
    },
    2: {
      name: "ale ani ja si tu kokot neválam",
      path: aniJaSiTuKokotNevalam,
      new: false,
    },
    3: {
      name: "čo riešiš, aké veci riešiš!",
      path: coRiesisAkeVeciRiesis,
      new: false,
    },
    4: {
      name: "do piči tam, kurva",
      path: doPiciTamKurva,
      new: false,
    },
    5: {
      name: "dobre okay tak, dobre...",
      path: dobreOkayTak,
      new: false,
    },
    6: {
      name: "kurva",
      path: kurva,
      new: false,
    },
    7: {
      name: "osemkrát sa niečo pýtam",
      path: osemKrat,
      new: false,
    },
    8: {
      name: "nechate ma kokotinu povedať",
      path: nechateMaKokotinuPovedat,
      new: false,
    },
    9: {
      name: "nekomunikujú, to je katastrofa",
      path: nekomunikuju,
      new: false,
    },
    10: {
      name: "no...no...",
      path: noNo,
      new: false,
    },
    11: {
      name: "paráda",
      path: parada,
      new: false,
    },
    12: {
      name: "piče aj s takou spoluprácou",
      path: piceAjSTakouSpolupracou,
      new: false,
    },
    13: {
      name: "to je katastrofa",
      path: toJeKatastrofa,
      new: false,
    },
    14: {
      name: "už som naozaj nasratý do piči",
      path: uzSomNaozajNasratyDoPici,
      new: false,
    },
    15: {
      name: "dakujem, to je všetko...",
      path: dakujemToJeVsetko,
      new: false,
    },
    16: {
      name: "prečo ste ma tak zarezali do piči",
      path: precoSteMaTakZarezali,
      new: false,
    },
    17: {
      name: "do piče",
      path: doPice,
      new: false,
    },
    18: {
      name: "nekrič tu na mna, ja si tu kokot neválam",
      path: nekricTuNaMnaJaSiTuKokotNevalam,
      new: false,
    },
    19: {
      name: "prečo musíš furt kričať na nás",
      path: precoMusisFurtKricatNaNas,
      new: false,
    },
    20: {
      name: "však sa tu snažíme kurva robiť okolo vás",
      path: vsakSaTuSnazimeKurvaRobitOkoloVas,
      new: false,
    },
  };

  let navigate = useNavigate();

  const getNameOfFile = (path) => {
    const fileNameWithHash = path.split('/').pop();
    const fileName = fileNameWithHash.split('.').shift();

    return fileName + '.mp3';
  };

  const playAudio = (path) => {
    const audio = new Audio(path);
    audio.play();
  };

  return (
    <div className="container mx-auto p-5 h-full">
      <h1 className="text-4xl pt-10">Milujem svoju prácu!</h1>

      <h4 className="text-1xl text-slate-500 pb-5">Relaxačná aplikácia prospievajúca k zlepšeniu psychickej kondície zamestnanca.</h4>

      <div className="flex flex-wrap">
        {Object.keys(quotes).map(k => (
          <div key={k}
               className="
                  w-32 h-32 sm:h-40 sm:w-40
                  mr-5 mb-5
                  grid items-center justify-items-center
                  text-white text-center
                  bg-green-300
                  cursor-pointer
                  border-gray-700 hover:border-gray-700 border-transparent border-2
                  relative"
               onClick={() => {
                 playAudio(quotes[k].path);

                 const path = getNameOfFile(quotes[k].path);
                 navigate(`../${path}`, { replace: true });
               }}
          >
            {quotes[k].new && (
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-slate-300 uppercase last:mr-0 mr-1 absolute top-0 right-0">
                Nové
              </span>
            )}
            {quotes[k].name}
          </div>
        ))}
      </div>

      <div className="text-xs py-5 italic text-slate-500">
        inšpirované <a href="http://milujipraci.cz">http://milujipraci.cz</a> <br/>
        kontakt <a href = "mailto:milujempracu@protonmail.com">milujempracu@protonmail.com</a> <br/>
        verzia 0.1.1 (krista-boha-vam-dopice)
      </div>
    </div>
  );
}

export default App;
